
import { Component } from 'nuxt-property-decorator';
import AdPopup from '~/components/AdPopup.vue';

import MetadatasMixin from '~/ts/mixins/metadata';
import { WebAd } from '~/ts/interfaces/types';
import { WebAdApi } from '~/ts/services/api/WebAdApi';
import BaseToast from '~/components/base/BaseToast.vue';
import TheFooter from '~/components/TheFooter.vue';
import TheHeader from '~/components/TheHeader.vue';
// import SmartBanner from '~/components/SmartBanner.vue';

@Component({
  components: { AdPopup, BaseToast, TheFooter, TheHeader },
})
export default class Default extends MetadatasMixin {
  private ad: WebAd | null | undefined = null;
  private adApi: WebAdApi = new WebAdApi();
  // private displaySmartBanner: boolean = false;

  mounted() {
    this.fetchWebPopup();
    // this.checkForSmartBanner();
  }

  // private checkForSmartBanner() {
  //   // Get the device by the user agent
  //   const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  //   // If the user is on a mobile device and hasn't dismissed the banner, display it
  //   if (isMobile) {
  //     this.displaySmartBanner = true;
  //   }
  // }

  // handleSmartBannerClosed() {
  //   this.displaySmartBanner = false;
  // }

  async fetchWebPopup() {
    // We fetch all 24h the web popup, use the local storage to avoid fetching it every time
    const lastFetch = localStorage.getItem('lastFetchWebPopup');
    if (lastFetch && this.$moment().diff(this.$moment(lastFetch), 'hours') < 24) return;

    if (this.$route.hash) {
      const hashEl = document.querySelector(this.$route.hash);
      if (hashEl) hashEl.scrollIntoView({ behavior: 'smooth' });
    }

    if (this.$route.name && (this.$route.name.startsWith('premium__') || this.$route.name.startsWith('map__'))) return;

    await this.adApi.getAd('web_popup').then((ad) => {
      this.ad = ad;
      localStorage.setItem('lastFetchWebPopup', this.$moment().toISOString());
    });
  }
}

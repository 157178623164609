export const ActivityItem = () => import('../../components/ActivityItem.vue' /* webpackChunkName: "components/activity-item" */).then(c => wrapFunctional(c.default || c))
export const ActivityProButton = () => import('../../components/ActivityProButton.vue' /* webpackChunkName: "components/activity-pro-button" */).then(c => wrapFunctional(c.default || c))
export const AdPopup = () => import('../../components/AdPopup.vue' /* webpackChunkName: "components/ad-popup" */).then(c => wrapFunctional(c.default || c))
export const AddButton = () => import('../../components/AddButton.vue' /* webpackChunkName: "components/add-button" */).then(c => wrapFunctional(c.default || c))
export const AdvertiserTab = () => import('../../components/AdvertiserTab.vue' /* webpackChunkName: "components/advertiser-tab" */).then(c => wrapFunctional(c.default || c))
export const BarAmbassador = () => import('../../components/BarAmbassador.vue' /* webpackChunkName: "components/bar-ambassador" */).then(c => wrapFunctional(c.default || c))
export const CaramapsHostItem = () => import('../../components/CaramapsHostItem.vue' /* webpackChunkName: "components/caramaps-host-item" */).then(c => wrapFunctional(c.default || c))
export const CarasCarousel = () => import('../../components/CarasCarousel.vue' /* webpackChunkName: "components/caras-carousel" */).then(c => wrapFunctional(c.default || c))
export const CarouselGuest = () => import('../../components/CarouselGuest.vue' /* webpackChunkName: "components/carousel-guest" */).then(c => wrapFunctional(c.default || c))
export const CircularProgress = () => import('../../components/CircularProgress.vue' /* webpackChunkName: "components/circular-progress" */).then(c => wrapFunctional(c.default || c))
export const CommentCard = () => import('../../components/CommentCard.vue' /* webpackChunkName: "components/comment-card" */).then(c => wrapFunctional(c.default || c))
export const CustomAutocomplete = () => import('../../components/CustomAutocomplete.vue' /* webpackChunkName: "components/custom-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const DiscoverBlock = () => import('../../components/DiscoverBlock.vue' /* webpackChunkName: "components/discover-block" */).then(c => wrapFunctional(c.default || c))
export const DotFill = () => import('../../components/DotFill.vue' /* webpackChunkName: "components/dot-fill" */).then(c => wrapFunctional(c.default || c))
export const DrawerLeft = () => import('../../components/DrawerLeft.vue' /* webpackChunkName: "components/drawer-left" */).then(c => wrapFunctional(c.default || c))
export const DrawerRight = () => import('../../components/DrawerRight.vue' /* webpackChunkName: "components/drawer-right" */).then(c => wrapFunctional(c.default || c))
export const DrawerRightLink = () => import('../../components/DrawerRightLink.vue' /* webpackChunkName: "components/drawer-right-link" */).then(c => wrapFunctional(c.default || c))
export const DropdownHeaderLink = () => import('../../components/DropdownHeaderLink.vue' /* webpackChunkName: "components/dropdown-header-link" */).then(c => wrapFunctional(c.default || c))
export const LandingSection = () => import('../../components/LandingSection.vue' /* webpackChunkName: "components/landing-section" */).then(c => wrapFunctional(c.default || c))
export const LandingSectionTwo = () => import('../../components/LandingSectionTwo.vue' /* webpackChunkName: "components/landing-section-two" */).then(c => wrapFunctional(c.default || c))
export const LangSwitcher = () => import('../../components/LangSwitcher.vue' /* webpackChunkName: "components/lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const LastCommunityComments = () => import('../../components/LastCommunityComments.vue' /* webpackChunkName: "components/last-community-comments" */).then(c => wrapFunctional(c.default || c))
export const LeaderboardCard = () => import('../../components/LeaderboardCard.vue' /* webpackChunkName: "components/leaderboard-card" */).then(c => wrapFunctional(c.default || c))
export const LegalsText = () => import('../../components/LegalsText.vue' /* webpackChunkName: "components/legals-text" */).then(c => wrapFunctional(c.default || c))
export const LoginRegisterButtons = () => import('../../components/LoginRegisterButtons.vue' /* webpackChunkName: "components/login-register-buttons" */).then(c => wrapFunctional(c.default || c))
export const MapListingCarouselTemplate = () => import('../../components/MapListingCarouselTemplate.vue' /* webpackChunkName: "components/map-listing-carousel-template" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const PageBlockModal = () => import('../../components/PageBlockModal.vue' /* webpackChunkName: "components/page-block-modal" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const PersonAvatar = () => import('../../components/PersonAvatar.vue' /* webpackChunkName: "components/person-avatar" */).then(c => wrapFunctional(c.default || c))
export const PersonDetailsField = () => import('../../components/PersonDetailsField.vue' /* webpackChunkName: "components/person-details-field" */).then(c => wrapFunctional(c.default || c))
export const PhoneInput = () => import('../../components/PhoneInput.vue' /* webpackChunkName: "components/phone-input" */).then(c => wrapFunctional(c.default || c))
export const PremiumPopup = () => import('../../components/PremiumPopup.vue' /* webpackChunkName: "components/premium-popup" */).then(c => wrapFunctional(c.default || c))
export const PromoCodePopup = () => import('../../components/PromoCodePopup.vue' /* webpackChunkName: "components/promo-code-popup" */).then(c => wrapFunctional(c.default || c))
export const ShareModal = () => import('../../components/ShareModal.vue' /* webpackChunkName: "components/share-modal" */).then(c => wrapFunctional(c.default || c))
export const SharePoiModal = () => import('../../components/SharePoiModal.vue' /* webpackChunkName: "components/share-poi-modal" */).then(c => wrapFunctional(c.default || c))
export const SmartBanner = () => import('../../components/SmartBanner.vue' /* webpackChunkName: "components/smart-banner" */).then(c => wrapFunctional(c.default || c))
export const StripeForm = () => import('../../components/StripeForm.vue' /* webpackChunkName: "components/stripe-form" */).then(c => wrapFunctional(c.default || c))
export const StripeProForm = () => import('../../components/StripeProForm.vue' /* webpackChunkName: "components/stripe-pro-form" */).then(c => wrapFunctional(c.default || c))
export const SubHeader = () => import('../../components/SubHeader.vue' /* webpackChunkName: "components/sub-header" */).then(c => wrapFunctional(c.default || c))
export const SubHeaderLink = () => import('../../components/SubHeaderLink.vue' /* webpackChunkName: "components/sub-header-link" */).then(c => wrapFunctional(c.default || c))
export const TheFooter = () => import('../../components/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c))
export const TheHeader = () => import('../../components/TheHeader.vue' /* webpackChunkName: "components/the-header" */).then(c => wrapFunctional(c.default || c))
export const ActivityActivitiesMap = () => import('../../components/activity/ActivitiesMap.vue' /* webpackChunkName: "components/activity-activities-map" */).then(c => wrapFunctional(c.default || c))
export const ActivityFullDetails = () => import('../../components/activity/ActivityFullDetails.vue' /* webpackChunkName: "components/activity-full-details" */).then(c => wrapFunctional(c.default || c))
export const ActivityPreview = () => import('../../components/activity/ActivityPreview.vue' /* webpackChunkName: "components/activity-preview" */).then(c => wrapFunctional(c.default || c))
export const ActivityEditActivitiesList = () => import('../../components/activity/EditActivitiesList.vue' /* webpackChunkName: "components/activity-edit-activities-list" */).then(c => wrapFunctional(c.default || c))
export const ActivityEditMediasList = () => import('../../components/activity/EditMediasList.vue' /* webpackChunkName: "components/activity-edit-medias-list" */).then(c => wrapFunctional(c.default || c))
export const ActivityInvoicingForm = () => import('../../components/activity/InvoicingForm.vue' /* webpackChunkName: "components/activity-invoicing-form" */).then(c => wrapFunctional(c.default || c))
export const CarasHeader = () => import('../../components/caras/CarasHeader.vue' /* webpackChunkName: "components/caras-header" */).then(c => wrapFunctional(c.default || c))
export const CarasLevelHeader = () => import('../../components/caras/CarasLevelHeader.vue' /* webpackChunkName: "components/caras-level-header" */).then(c => wrapFunctional(c.default || c))
export const HomeActivitiesComponent = () => import('../../components/home/ActivitiesComponent.vue' /* webpackChunkName: "components/home-activities-component" */).then(c => wrapFunctional(c.default || c))
export const HomeCommentsComponent = () => import('../../components/home/CommentsComponent.vue' /* webpackChunkName: "components/home-comments-component" */).then(c => wrapFunctional(c.default || c))
export const HomeFavoriteComponent = () => import('../../components/home/FavoriteComponent.vue' /* webpackChunkName: "components/home-favorite-component" */).then(c => wrapFunctional(c.default || c))
export const HomeComponent = () => import('../../components/home/HomeComponent.vue' /* webpackChunkName: "components/home-component" */).then(c => wrapFunctional(c.default || c))
export const HomeLinkCard = () => import('../../components/home/LinkCard.vue' /* webpackChunkName: "components/home-link-card" */).then(c => wrapFunctional(c.default || c))
export const HomePhotosComponent = () => import('../../components/home/PhotosComponent.vue' /* webpackChunkName: "components/home-photos-component" */).then(c => wrapFunctional(c.default || c))
export const HomePlaceComponent = () => import('../../components/home/PlaceComponent.vue' /* webpackChunkName: "components/home-place-component" */).then(c => wrapFunctional(c.default || c))
export const HomePremiumComponent = () => import('../../components/home/PremiumComponent.vue' /* webpackChunkName: "components/home-premium-component" */).then(c => wrapFunctional(c.default || c))
export const HomeTripComponent = () => import('../../components/home/TripComponent.vue' /* webpackChunkName: "components/home-trip-component" */).then(c => wrapFunctional(c.default || c))
export const ApplicationPhoneBlock = () => import('../../components/application/PhoneBlock.vue' /* webpackChunkName: "components/application-phone-block" */).then(c => wrapFunctional(c.default || c))
export const ApplicationStoresLinks = () => import('../../components/application/StoresLinks.vue' /* webpackChunkName: "components/application-stores-links" */).then(c => wrapFunctional(c.default || c))
export const IconAdIcon = () => import('../../components/icon/AdIcon.vue' /* webpackChunkName: "components/icon-ad-icon" */).then(c => wrapFunctional(c.default || c))
export const IconAddIcon = () => import('../../components/icon/AddIcon.vue' /* webpackChunkName: "components/icon-add-icon" */).then(c => wrapFunctional(c.default || c))
export const IconArrowDownCircleIcon = () => import('../../components/icon/ArrowDownCircleIcon.vue' /* webpackChunkName: "components/icon-arrow-down-circle-icon" */).then(c => wrapFunctional(c.default || c))
export const IconArrowRightIcon = () => import('../../components/icon/ArrowRightIcon.vue' /* webpackChunkName: "components/icon-arrow-right-icon" */).then(c => wrapFunctional(c.default || c))
export const IconArrowRightThinIcon = () => import('../../components/icon/ArrowRightThinIcon.vue' /* webpackChunkName: "components/icon-arrow-right-thin-icon" */).then(c => wrapFunctional(c.default || c))
export const IconArrowUpWideShortIcon = () => import('../../components/icon/ArrowUpWideShortIcon.vue' /* webpackChunkName: "components/icon-arrow-up-wide-short-icon" */).then(c => wrapFunctional(c.default || c))
export const IconBriefcaseIcon = () => import('../../components/icon/BriefcaseIcon.vue' /* webpackChunkName: "components/icon-briefcase-icon" */).then(c => wrapFunctional(c.default || c))
export const IconCameraIcon = () => import('../../components/icon/CameraIcon.vue' /* webpackChunkName: "components/icon-camera-icon" */).then(c => wrapFunctional(c.default || c))
export const IconCameraPlusOutlineIcon = () => import('../../components/icon/CameraPlusOutlineIcon.vue' /* webpackChunkName: "components/icon-camera-plus-outline-icon" */).then(c => wrapFunctional(c.default || c))
export const IconCarasIcon = () => import('../../components/icon/CarasIcon.vue' /* webpackChunkName: "components/icon-caras-icon" */).then(c => wrapFunctional(c.default || c))
export const IconCarasOutlineIcon = () => import('../../components/icon/CarasOutlineIcon.vue' /* webpackChunkName: "components/icon-caras-outline-icon" */).then(c => wrapFunctional(c.default || c))
export const IconCaravanIcon = () => import('../../components/icon/CaravanIcon.vue' /* webpackChunkName: "components/icon-caravan-icon" */).then(c => wrapFunctional(c.default || c))
export const IconCheckIcon = () => import('../../components/icon/CheckIcon.vue' /* webpackChunkName: "components/icon-check-icon" */).then(c => wrapFunctional(c.default || c))
export const IconClockTimeNineOutlineIcon = () => import('../../components/icon/ClockTimeNineOutlineIcon.vue' /* webpackChunkName: "components/icon-clock-time-nine-outline-icon" */).then(c => wrapFunctional(c.default || c))
export const IconCloseIcon = () => import('../../components/icon/CloseIcon.vue' /* webpackChunkName: "components/icon-close-icon" */).then(c => wrapFunctional(c.default || c))
export const IconCogIcon = () => import('../../components/icon/CogIcon.vue' /* webpackChunkName: "components/icon-cog-icon" */).then(c => wrapFunctional(c.default || c))
export const IconCommentIcon = () => import('../../components/icon/CommentIcon.vue' /* webpackChunkName: "components/icon-comment-icon" */).then(c => wrapFunctional(c.default || c))
export const IconCompassIcon = () => import('../../components/icon/CompassIcon.vue' /* webpackChunkName: "components/icon-compass-icon" */).then(c => wrapFunctional(c.default || c))
export const IconCopyIcon = () => import('../../components/icon/CopyIcon.vue' /* webpackChunkName: "components/icon-copy-icon" */).then(c => wrapFunctional(c.default || c))
export const IconCrossIcon = () => import('../../components/icon/CrossIcon.vue' /* webpackChunkName: "components/icon-cross-icon" */).then(c => wrapFunctional(c.default || c))
export const IconCrownIcon = () => import('../../components/icon/CrownIcon.vue' /* webpackChunkName: "components/icon-crown-icon" */).then(c => wrapFunctional(c.default || c))
export const IconDownloadIcon = () => import('../../components/icon/DownloadIcon.vue' /* webpackChunkName: "components/icon-download-icon" */).then(c => wrapFunctional(c.default || c))
export const IconEarthIcon = () => import('../../components/icon/EarthIcon.vue' /* webpackChunkName: "components/icon-earth-icon" */).then(c => wrapFunctional(c.default || c))
export const IconEmailIcon = () => import('../../components/icon/EmailIcon.vue' /* webpackChunkName: "components/icon-email-icon" */).then(c => wrapFunctional(c.default || c))
export const IconEmptyHeartIcon = () => import('../../components/icon/EmptyHeartIcon.vue' /* webpackChunkName: "components/icon-empty-heart-icon" */).then(c => wrapFunctional(c.default || c))
export const IconFacebookIcon = () => import('../../components/icon/FacebookIcon.vue' /* webpackChunkName: "components/icon-facebook-icon" */).then(c => wrapFunctional(c.default || c))
export const IconFilterIcon = () => import('../../components/icon/FilterIcon.vue' /* webpackChunkName: "components/icon-filter-icon" */).then(c => wrapFunctional(c.default || c))
export const IconFingerUpIcon = () => import('../../components/icon/FingerUpIcon.vue' /* webpackChunkName: "components/icon-finger-up-icon" */).then(c => wrapFunctional(c.default || c))
export const IconForbiddenIcon = () => import('../../components/icon/ForbiddenIcon.vue' /* webpackChunkName: "components/icon-forbidden-icon" */).then(c => wrapFunctional(c.default || c))
export const IconHeartIcon = () => import('../../components/icon/HeartIcon.vue' /* webpackChunkName: "components/icon-heart-icon" */).then(c => wrapFunctional(c.default || c))
export const IconHelpIcon = () => import('../../components/icon/HelpIcon.vue' /* webpackChunkName: "components/icon-help-icon" */).then(c => wrapFunctional(c.default || c))
export const IconHomeIcon = () => import('../../components/icon/HomeIcon.vue' /* webpackChunkName: "components/icon-home-icon" */).then(c => wrapFunctional(c.default || c))
export const IconLeftArrowIcon = () => import('../../components/icon/LeftArrowIcon.vue' /* webpackChunkName: "components/icon-left-arrow-icon" */).then(c => wrapFunctional(c.default || c))
export const IconLikedCommentIcon = () => import('../../components/icon/LikedCommentIcon.vue' /* webpackChunkName: "components/icon-liked-comment-icon" */).then(c => wrapFunctional(c.default || c))
export const IconLoadingIcon = () => import('../../components/icon/LoadingIcon.vue' /* webpackChunkName: "components/icon-loading-icon" */).then(c => wrapFunctional(c.default || c))
export const IconLocationArrowIcon = () => import('../../components/icon/LocationArrowIcon.vue' /* webpackChunkName: "components/icon-location-arrow-icon" */).then(c => wrapFunctional(c.default || c))
export const IconMapPointIcon = () => import('../../components/icon/MapPointIcon.vue' /* webpackChunkName: "components/icon-map-point-icon" */).then(c => wrapFunctional(c.default || c))
export const IconMoreIcon = () => import('../../components/icon/MoreIcon.vue' /* webpackChunkName: "components/icon-more-icon" */).then(c => wrapFunctional(c.default || c))
export const IconPencilIcon = () => import('../../components/icon/PencilIcon.vue' /* webpackChunkName: "components/icon-pencil-icon" */).then(c => wrapFunctional(c.default || c))
export const IconPlanetIcon = () => import('../../components/icon/PlanetIcon.vue' /* webpackChunkName: "components/icon-planet-icon" */).then(c => wrapFunctional(c.default || c))
export const IconPlayIcon = () => import('../../components/icon/PlayIcon.vue' /* webpackChunkName: "components/icon-play-icon" */).then(c => wrapFunctional(c.default || c))
export const IconPlusThickIcon = () => import('../../components/icon/PlusThickIcon.vue' /* webpackChunkName: "components/icon-plus-thick-icon" */).then(c => wrapFunctional(c.default || c))
export const IconPrintIcon = () => import('../../components/icon/PrintIcon.vue' /* webpackChunkName: "components/icon-print-icon" */).then(c => wrapFunctional(c.default || c))
export const IconPrinterOutlineIcon = () => import('../../components/icon/PrinterOutlineIcon.vue' /* webpackChunkName: "components/icon-printer-outline-icon" */).then(c => wrapFunctional(c.default || c))
export const IconProximityIcon = () => import('../../components/icon/ProximityIcon.vue' /* webpackChunkName: "components/icon-proximity-icon" */).then(c => wrapFunctional(c.default || c))
export const IconRoadVariantIcon = () => import('../../components/icon/RoadVariantIcon.vue' /* webpackChunkName: "components/icon-road-variant-icon" */).then(c => wrapFunctional(c.default || c))
export const IconRoundedArrowLeftIcon = () => import('../../components/icon/RoundedArrowLeftIcon.vue' /* webpackChunkName: "components/icon-rounded-arrow-left-icon" */).then(c => wrapFunctional(c.default || c))
export const IconRoundedArrowRightIcon = () => import('../../components/icon/RoundedArrowRightIcon.vue' /* webpackChunkName: "components/icon-rounded-arrow-right-icon" */).then(c => wrapFunctional(c.default || c))
export const IconRouteIcon = () => import('../../components/icon/RouteIcon.vue' /* webpackChunkName: "components/icon-route-icon" */).then(c => wrapFunctional(c.default || c))
export const IconSaveIcon = () => import('../../components/icon/SaveIcon.vue' /* webpackChunkName: "components/icon-save-icon" */).then(c => wrapFunctional(c.default || c))
export const IconShareIcon = () => import('../../components/icon/ShareIcon.vue' /* webpackChunkName: "components/icon-share-icon" */).then(c => wrapFunctional(c.default || c))
export const IconSignalIcon = () => import('../../components/icon/SignalIcon.vue' /* webpackChunkName: "components/icon-signal-icon" */).then(c => wrapFunctional(c.default || c))
export const IconSimpleArrowRightIcon = () => import('../../components/icon/SimpleArrowRightIcon.vue' /* webpackChunkName: "components/icon-simple-arrow-right-icon" */).then(c => wrapFunctional(c.default || c))
export const IconStarIcon = () => import('../../components/icon/StarIcon.vue' /* webpackChunkName: "components/icon-star-icon" */).then(c => wrapFunctional(c.default || c))
export const IconThumbUpIcon = () => import('../../components/icon/ThumbUpIcon.vue' /* webpackChunkName: "components/icon-thumb-up-icon" */).then(c => wrapFunctional(c.default || c))
export const IconTickIcon = () => import('../../components/icon/TickIcon.vue' /* webpackChunkName: "components/icon-tick-icon" */).then(c => wrapFunctional(c.default || c))
export const IconTrashCanOutlineIcon = () => import('../../components/icon/TrashCanOutlineIcon.vue' /* webpackChunkName: "components/icon-trash-can-outline-icon" */).then(c => wrapFunctional(c.default || c))
export const IconTravelIcon = () => import('../../components/icon/TravelIcon.vue' /* webpackChunkName: "components/icon-travel-icon" */).then(c => wrapFunctional(c.default || c))
export const IconTravelStepIcon = () => import('../../components/icon/TravelStepIcon.vue' /* webpackChunkName: "components/icon-travel-step-icon" */).then(c => wrapFunctional(c.default || c))
export const IconTrayArrowDownIcon = () => import('../../components/icon/TrayArrowDownIcon.vue' /* webpackChunkName: "components/icon-tray-arrow-down-icon" */).then(c => wrapFunctional(c.default || c))
export const IconTrayArrowUpIcon = () => import('../../components/icon/TrayArrowUpIcon.vue' /* webpackChunkName: "components/icon-tray-arrow-up-icon" */).then(c => wrapFunctional(c.default || c))
export const IconTriangleIcon = () => import('../../components/icon/TriangleIcon.vue' /* webpackChunkName: "components/icon-triangle-icon" */).then(c => wrapFunctional(c.default || c))
export const IconTriangleOutlineIcon = () => import('../../components/icon/TriangleOutlineIcon.vue' /* webpackChunkName: "components/icon-triangle-outline-icon" */).then(c => wrapFunctional(c.default || c))
export const IconTwitterIcon = () => import('../../components/icon/TwitterIcon.vue' /* webpackChunkName: "components/icon-twitter-icon" */).then(c => wrapFunctional(c.default || c))
export const IconUsersIcon = () => import('../../components/icon/UsersIcon.vue' /* webpackChunkName: "components/icon-users-icon" */).then(c => wrapFunctional(c.default || c))
export const IconVanShuttleIcon = () => import('../../components/icon/VanShuttleIcon.vue' /* webpackChunkName: "components/icon-van-shuttle-icon" */).then(c => wrapFunctional(c.default || c))
export const IconViewListIcon = () => import('../../components/icon/ViewListIcon.vue' /* webpackChunkName: "components/icon-view-list-icon" */).then(c => wrapFunctional(c.default || c))
export const IconZoomIcon = () => import('../../components/icon/ZoomIcon.vue' /* webpackChunkName: "components/icon-zoom-icon" */).then(c => wrapFunctional(c.default || c))
export const BaseAlert = () => import('../../components/base/BaseAlert.vue' /* webpackChunkName: "components/base-alert" */).then(c => wrapFunctional(c.default || c))
export const BaseBreadcrumb = () => import('../../components/base/BaseBreadcrumb.vue' /* webpackChunkName: "components/base-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const BaseButton = () => import('../../components/base/BaseButton.vue' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c))
export const BaseCard = () => import('../../components/base/BaseCard.vue' /* webpackChunkName: "components/base-card" */).then(c => wrapFunctional(c.default || c))
export const BaseCarousel = () => import('../../components/base/BaseCarousel.vue' /* webpackChunkName: "components/base-carousel" */).then(c => wrapFunctional(c.default || c))
export const BaseCarouselPro = () => import('../../components/base/BaseCarouselPro.vue' /* webpackChunkName: "components/base-carousel-pro" */).then(c => wrapFunctional(c.default || c))
export const BaseCheckBox = () => import('../../components/base/BaseCheckBox.vue' /* webpackChunkName: "components/base-check-box" */).then(c => wrapFunctional(c.default || c))
export const BaseCropper = () => import('../../components/base/BaseCropper.vue' /* webpackChunkName: "components/base-cropper" */).then(c => wrapFunctional(c.default || c))
export const BaseDate = () => import('../../components/base/BaseDate.vue' /* webpackChunkName: "components/base-date" */).then(c => wrapFunctional(c.default || c))
export const BaseDialog = () => import('../../components/base/BaseDialog.vue' /* webpackChunkName: "components/base-dialog" */).then(c => wrapFunctional(c.default || c))
export const BaseDoubleRangeInput = () => import('../../components/base/BaseDoubleRangeInput.vue' /* webpackChunkName: "components/base-double-range-input" */).then(c => wrapFunctional(c.default || c))
export const BaseFilePond = () => import('../../components/base/BaseFilePond.vue' /* webpackChunkName: "components/base-file-pond" */).then(c => wrapFunctional(c.default || c))
export const BaseH1 = () => import('../../components/base/BaseH1.vue' /* webpackChunkName: "components/base-h1" */).then(c => wrapFunctional(c.default || c))
export const BaseH2 = () => import('../../components/base/BaseH2.vue' /* webpackChunkName: "components/base-h2" */).then(c => wrapFunctional(c.default || c))
export const BaseH3 = () => import('../../components/base/BaseH3.vue' /* webpackChunkName: "components/base-h3" */).then(c => wrapFunctional(c.default || c))
export const BaseIcon = () => import('../../components/base/BaseIcon.vue' /* webpackChunkName: "components/base-icon" */).then(c => wrapFunctional(c.default || c))
export const BaseIconButton = () => import('../../components/base/BaseIconButton.vue' /* webpackChunkName: "components/base-icon-button" */).then(c => wrapFunctional(c.default || c))
export const BaseInput = () => import('../../components/base/BaseInput.vue' /* webpackChunkName: "components/base-input" */).then(c => wrapFunctional(c.default || c))
export const BaseLabel = () => import('../../components/base/BaseLabel.vue' /* webpackChunkName: "components/base-label" */).then(c => wrapFunctional(c.default || c))
export const BaseLightBox = () => import('../../components/base/BaseLightBox.vue' /* webpackChunkName: "components/base-light-box" */).then(c => wrapFunctional(c.default || c))
export const BaseModal = () => import('../../components/base/BaseModal.vue' /* webpackChunkName: "components/base-modal" */).then(c => wrapFunctional(c.default || c))
export const BasePreviewImage = () => import('../../components/base/BasePreviewImage.vue' /* webpackChunkName: "components/base-preview-image" */).then(c => wrapFunctional(c.default || c))
export const BaseSelect = () => import('../../components/base/BaseSelect.vue' /* webpackChunkName: "components/base-select" */).then(c => wrapFunctional(c.default || c))
export const BaseTextarea = () => import('../../components/base/BaseTextarea.vue' /* webpackChunkName: "components/base-textarea" */).then(c => wrapFunctional(c.default || c))
export const BaseTitle = () => import('../../components/base/BaseTitle.vue' /* webpackChunkName: "components/base-title" */).then(c => wrapFunctional(c.default || c))
export const BaseToast = () => import('../../components/base/BaseToast.vue' /* webpackChunkName: "components/base-toast" */).then(c => wrapFunctional(c.default || c))
export const BaseTooltip = () => import('../../components/base/BaseTooltip.vue' /* webpackChunkName: "components/base-tooltip" */).then(c => wrapFunctional(c.default || c))
export const BaseTruncateTooltip = () => import('../../components/base/BaseTruncateTooltip.vue' /* webpackChunkName: "components/base-truncate-tooltip" */).then(c => wrapFunctional(c.default || c))
export const BaseContainer = () => import('../../components/base/Container.vue' /* webpackChunkName: "components/base-container" */).then(c => wrapFunctional(c.default || c))
export const BaseCustomSelect = () => import('../../components/base/CustomSelect.vue' /* webpackChunkName: "components/base-custom-select" */).then(c => wrapFunctional(c.default || c))
export const MapActivityMap = () => import('../../components/map/ActivityMap.vue' /* webpackChunkName: "components/map-activity-map" */).then(c => wrapFunctional(c.default || c))
export const MapAddPictureModal = () => import('../../components/map/AddPictureModal.vue' /* webpackChunkName: "components/map-add-picture-modal" */).then(c => wrapFunctional(c.default || c))
export const MapContactModal = () => import('../../components/map/ContactModal.vue' /* webpackChunkName: "components/map-contact-modal" */).then(c => wrapFunctional(c.default || c))
export const MapEmailShareModal = () => import('../../components/map/EmailShareModal.vue' /* webpackChunkName: "components/map-email-share-modal" */).then(c => wrapFunctional(c.default || c))
export const MapFilterBar = () => import('../../components/map/FilterBar.vue' /* webpackChunkName: "components/map-filter-bar" */).then(c => wrapFunctional(c.default || c))
export const MapFilterContainer = () => import('../../components/map/FilterContainer.vue' /* webpackChunkName: "components/map-filter-container" */).then(c => wrapFunctional(c.default || c))
export const MapFilterDrawerLeft = () => import('../../components/map/FilterDrawerLeft.vue' /* webpackChunkName: "components/map-filter-drawer-left" */).then(c => wrapFunctional(c.default || c))
export const MapFilterMapPoint = () => import('../../components/map/FilterMapPoint.vue' /* webpackChunkName: "components/map-filter-map-point" */).then(c => wrapFunctional(c.default || c))
export const MapFilterSearch = () => import('../../components/map/FilterSearch.vue' /* webpackChunkName: "components/map-filter-search" */).then(c => wrapFunctional(c.default || c))
export const MapFilterStepsList = () => import('../../components/map/FilterStepsList.vue' /* webpackChunkName: "components/map-filter-steps-list" */).then(c => wrapFunctional(c.default || c))
export const MapFilterTripsList = () => import('../../components/map/FilterTripsList.vue' /* webpackChunkName: "components/map-filter-trips-list" */).then(c => wrapFunctional(c.default || c))
export const MapForgetNotationModal = () => import('../../components/map/ForgetNotationModal.vue' /* webpackChunkName: "components/map-forget-notation-modal" */).then(c => wrapFunctional(c.default || c))
export const MapMessageModal = () => import('../../components/map/MessageModal.vue' /* webpackChunkName: "components/map-message-modal" */).then(c => wrapFunctional(c.default || c))
export const MapOfferModal = () => import('../../components/map/OfferModal.vue' /* webpackChunkName: "components/map-offer-modal" */).then(c => wrapFunctional(c.default || c))
export const MapPoiMap = () => import('../../components/map/PoiMap.vue' /* webpackChunkName: "components/map-poi-map" */).then(c => wrapFunctional(c.default || c))
export const MapPreviewPictureModal = () => import('../../components/map/PreviewPictureModal.vue' /* webpackChunkName: "components/map-preview-picture-modal" */).then(c => wrapFunctional(c.default || c))
export const MapReplyMessageModal = () => import('../../components/map/ReplyMessageModal.vue' /* webpackChunkName: "components/map-reply-message-modal" */).then(c => wrapFunctional(c.default || c))
export const MapServiceModal = () => import('../../components/map/ServiceModal.vue' /* webpackChunkName: "components/map-service-modal" */).then(c => wrapFunctional(c.default || c))
export const MapSuggestionModal = () => import('../../components/map/SuggestionModal.vue' /* webpackChunkName: "components/map-suggestion-modal" */).then(c => wrapFunctional(c.default || c))
export const MapTravelForm = () => import('../../components/map/TravelForm.vue' /* webpackChunkName: "components/map-travel-form" */).then(c => wrapFunctional(c.default || c))
export const MapWeatherModal = () => import('../../components/map/WeatherModal.vue' /* webpackChunkName: "components/map-weather-modal" */).then(c => wrapFunctional(c.default || c))
export const PartnerPage = () => import('../../components/partner/PartnerPage.vue' /* webpackChunkName: "components/partner-page" */).then(c => wrapFunctional(c.default || c))
export const PartnerPreview = () => import('../../components/partner/PartnerPreview.vue' /* webpackChunkName: "components/partner-preview" */).then(c => wrapFunctional(c.default || c))
export const PartnerSpecificPage = () => import('../../components/partner/PartnerSpecificPage.vue' /* webpackChunkName: "components/partner-specific-page" */).then(c => wrapFunctional(c.default || c))
export const PointOfInterestPoiShareModal = () => import('../../components/point_of_interest/PoiShareModal.vue' /* webpackChunkName: "components/point-of-interest-poi-share-modal" */).then(c => wrapFunctional(c.default || c))
export const PointOfInterestRevisionPreview = () => import('../../components/point_of_interest/RevisionPreview.vue' /* webpackChunkName: "components/point-of-interest-revision-preview" */).then(c => wrapFunctional(c.default || c))
export const PointOfInterestRevisionPreviewButtons = () => import('../../components/point_of_interest/RevisionPreviewButtons.vue' /* webpackChunkName: "components/point-of-interest-revision-preview-buttons" */).then(c => wrapFunctional(c.default || c))
export const ProfileEditAboutYou = () => import('../../components/profile/ProfileEditAboutYou.vue' /* webpackChunkName: "components/profile-edit-about-you" */).then(c => wrapFunctional(c.default || c))
export const ProfileEditCamperVan = () => import('../../components/profile/ProfileEditCamperVan.vue' /* webpackChunkName: "components/profile-edit-camper-van" */).then(c => wrapFunctional(c.default || c))
export const ProfileEditConnexion = () => import('../../components/profile/ProfileEditConnexion.vue' /* webpackChunkName: "components/profile-edit-connexion" */).then(c => wrapFunctional(c.default || c))
export const ProfileEditNewsletter = () => import('../../components/profile/ProfileEditNewsletter.vue' /* webpackChunkName: "components/profile-edit-newsletter" */).then(c => wrapFunctional(c.default || c))
export const ProsOffersTable = () => import('../../components/pros/OffersTable.vue' /* webpackChunkName: "components/pros-offers-table" */).then(c => wrapFunctional(c.default || c))
export const ProsPaymentModal = () => import('../../components/pros/PaymentModal.vue' /* webpackChunkName: "components/pros-payment-modal" */).then(c => wrapFunctional(c.default || c))
export const ProsProCard = () => import('../../components/pros/ProCard.vue' /* webpackChunkName: "components/pros-pro-card" */).then(c => wrapFunctional(c.default || c))
export const ProsProContactForm = () => import('../../components/pros/ProContactForm.vue' /* webpackChunkName: "components/pros-pro-contact-form" */).then(c => wrapFunctional(c.default || c))
export const ProsProDetailedItem = () => import('../../components/pros/ProDetailedItem.vue' /* webpackChunkName: "components/pros-pro-detailed-item" */).then(c => wrapFunctional(c.default || c))
export const ProsProListItems = () => import('../../components/pros/ProListItems.vue' /* webpackChunkName: "components/pros-pro-list-items" */).then(c => wrapFunctional(c.default || c))
export const RevisionEditCommentsList = () => import('../../components/revision/EditCommentsList.vue' /* webpackChunkName: "components/revision-edit-comments-list" */).then(c => wrapFunctional(c.default || c))
export const RevisionEditPicturesList = () => import('../../components/revision/EditPicturesList.vue' /* webpackChunkName: "components/revision-edit-pictures-list" */).then(c => wrapFunctional(c.default || c))
export const RevisionEditPlacesList = () => import('../../components/revision/EditPlacesList.vue' /* webpackChunkName: "components/revision-edit-places-list" */).then(c => wrapFunctional(c.default || c))
export const RevisionRevisionsListingMapColumns = () => import('../../components/revision/RevisionsListingMapColumns.vue' /* webpackChunkName: "components/revision-revisions-listing-map-columns" */).then(c => wrapFunctional(c.default || c))
export const RevisionRevisionsMap = () => import('../../components/revision/RevisionsMap.vue' /* webpackChunkName: "components/revision-revisions-map" */).then(c => wrapFunctional(c.default || c))
export const ToolsCountryFlag = () => import('../../components/tools/CountryFlag.vue' /* webpackChunkName: "components/tools-country-flag" */).then(c => wrapFunctional(c.default || c))
export const ToolsNotationLikes = () => import('../../components/tools/NotationLikes.vue' /* webpackChunkName: "components/tools-notation-likes" */).then(c => wrapFunctional(c.default || c))
export const TripBestTrips = () => import('../../components/trip/BestTrips.vue' /* webpackChunkName: "components/trip-best-trips" */).then(c => wrapFunctional(c.default || c))
export const TripStep = () => import('../../components/trip/Step.vue' /* webpackChunkName: "components/trip-step" */).then(c => wrapFunctional(c.default || c))
export const TripStepDetails = () => import('../../components/trip/StepDetails.vue' /* webpackChunkName: "components/trip-step-details" */).then(c => wrapFunctional(c.default || c))
export const TripStepList = () => import('../../components/trip/StepList.vue' /* webpackChunkName: "components/trip-step-list" */).then(c => wrapFunctional(c.default || c))
export const TripStepNumber = () => import('../../components/trip/StepNumber.vue' /* webpackChunkName: "components/trip-step-number" */).then(c => wrapFunctional(c.default || c))
export const TripComments = () => import('../../components/trip/TripComments.vue' /* webpackChunkName: "components/trip-comments" */).then(c => wrapFunctional(c.default || c))
export const TripLegsList = () => import('../../components/trip/TripLegsList.vue' /* webpackChunkName: "components/trip-legs-list" */).then(c => wrapFunctional(c.default || c))
export const TripMap = () => import('../../components/trip/TripMap.vue' /* webpackChunkName: "components/trip-map" */).then(c => wrapFunctional(c.default || c))
export const TripTripsList = () => import('../../components/trip/TripsList.vue' /* webpackChunkName: "components/trip-trips-list" */).then(c => wrapFunctional(c.default || c))
export const ActivityStepperFormStepOne = () => import('../../components/activity/stepper_form/StepOne.vue' /* webpackChunkName: "components/activity-stepper-form-step-one" */).then(c => wrapFunctional(c.default || c))
export const ActivityStepperFormStepThree = () => import('../../components/activity/stepper_form/StepThree.vue' /* webpackChunkName: "components/activity-stepper-form-step-three" */).then(c => wrapFunctional(c.default || c))
export const ActivityStepperFormStepTwo = () => import('../../components/activity/stepper_form/StepTwo.vue' /* webpackChunkName: "components/activity-stepper-form-step-two" */).then(c => wrapFunctional(c.default || c))
export const IconAmbassadorAddAreaIcon = () => import('../../components/icon/Ambassador/AddAreaIcon.vue' /* webpackChunkName: "components/icon-ambassador-add-area-icon" */).then(c => wrapFunctional(c.default || c))
export const IconAmbassadorAddPictureIcon = () => import('../../components/icon/Ambassador/AddPictureIcon.vue' /* webpackChunkName: "components/icon-ambassador-add-picture-icon" */).then(c => wrapFunctional(c.default || c))
export const IconAmbassadorCommentPlaceIcon = () => import('../../components/icon/Ambassador/CommentPlaceIcon.vue' /* webpackChunkName: "components/icon-ambassador-comment-place-icon" */).then(c => wrapFunctional(c.default || c))
export const IconAmbassadorCompleteProfileIcon = () => import('../../components/icon/Ambassador/CompleteProfileIcon.vue' /* webpackChunkName: "components/icon-ambassador-complete-profile-icon" */).then(c => wrapFunctional(c.default || c))
export const IconAmbassadorLeftQuoteIcon = () => import('../../components/icon/Ambassador/LeftQuoteIcon.vue' /* webpackChunkName: "components/icon-ambassador-left-quote-icon" */).then(c => wrapFunctional(c.default || c))
export const IconAmbassadorLevel1Icon = () => import('../../components/icon/Ambassador/Level1Icon.vue' /* webpackChunkName: "components/icon-ambassador-level1-icon" */).then(c => wrapFunctional(c.default || c))
export const IconAmbassadorLevel2Icon = () => import('../../components/icon/Ambassador/Level2Icon.vue' /* webpackChunkName: "components/icon-ambassador-level2-icon" */).then(c => wrapFunctional(c.default || c))
export const IconAmbassadorLevel3Icon = () => import('../../components/icon/Ambassador/Level3Icon.vue' /* webpackChunkName: "components/icon-ambassador-level3-icon" */).then(c => wrapFunctional(c.default || c))
export const IconAmbassadorLevel4Icon = () => import('../../components/icon/Ambassador/Level4Icon.vue' /* webpackChunkName: "components/icon-ambassador-level4-icon" */).then(c => wrapFunctional(c.default || c))
export const IconAmbassadorLevel5Icon = () => import('../../components/icon/Ambassador/Level5Icon.vue' /* webpackChunkName: "components/icon-ambassador-level5-icon" */).then(c => wrapFunctional(c.default || c))
export const IconAmbassadorLevel6Icon = () => import('../../components/icon/Ambassador/Level6Icon.vue' /* webpackChunkName: "components/icon-ambassador-level6-icon" */).then(c => wrapFunctional(c.default || c))
export const IconAmbassadorLevel7Icon = () => import('../../components/icon/Ambassador/Level7Icon.vue' /* webpackChunkName: "components/icon-ambassador-level7-icon" */).then(c => wrapFunctional(c.default || c))
export const IconAmbassadorLevel8Icon = () => import('../../components/icon/Ambassador/Level8Icon.vue' /* webpackChunkName: "components/icon-ambassador-level8-icon" */).then(c => wrapFunctional(c.default || c))
export const IconAmbassadorRegisterIcon = () => import('../../components/icon/Ambassador/RegisterIcon.vue' /* webpackChunkName: "components/icon-ambassador-register-icon" */).then(c => wrapFunctional(c.default || c))
export const IconAmbassadorRightQuoteIcon = () => import('../../components/icon/Ambassador/RightQuoteIcon.vue' /* webpackChunkName: "components/icon-ambassador-right-quote-icon" */).then(c => wrapFunctional(c.default || c))
export const IconAmbassadorUpdateAreaIcon = () => import('../../components/icon/Ambassador/UpdateAreaIcon.vue' /* webpackChunkName: "components/icon-ambassador-update-area-icon" */).then(c => wrapFunctional(c.default || c))
export const MapPointOfInterestAttributeComponent = () => import('../../components/map/PointOfInterest/AttributeComponent.vue' /* webpackChunkName: "components/map-point-of-interest-attribute-component" */).then(c => wrapFunctional(c.default || c))
export const MapPointOfInterestAttributesList = () => import('../../components/map/PointOfInterest/AttributesList.vue' /* webpackChunkName: "components/map-point-of-interest-attributes-list" */).then(c => wrapFunctional(c.default || c))
export const MapPointOfInterestAuthorDetailCard = () => import('../../components/map/PointOfInterest/AuthorDetailCard.vue' /* webpackChunkName: "components/map-point-of-interest-author-detail-card" */).then(c => wrapFunctional(c.default || c))
export const MapPointOfInterestCityCard = () => import('../../components/map/PointOfInterest/CityCard.vue' /* webpackChunkName: "components/map-point-of-interest-city-card" */).then(c => wrapFunctional(c.default || c))
export const MapPointOfInterestFavoriteButton = () => import('../../components/map/PointOfInterest/FavoriteButton.vue' /* webpackChunkName: "components/map-point-of-interest-favorite-button" */).then(c => wrapFunctional(c.default || c))
export const MapPointOfInterestPictureCommentSubmittedModal = () => import('../../components/map/PointOfInterest/PictureCommentSubmittedModal.vue' /* webpackChunkName: "components/map-point-of-interest-picture-comment-submitted-modal" */).then(c => wrapFunctional(c.default || c))
export const MapPointOfInterestPoiActivities = () => import('../../components/map/PointOfInterest/PoiActivities.vue' /* webpackChunkName: "components/map-point-of-interest-poi-activities" */).then(c => wrapFunctional(c.default || c))
export const MapPointOfInterestPoiAttributes = () => import('../../components/map/PointOfInterest/PoiAttributes.vue' /* webpackChunkName: "components/map-point-of-interest-poi-attributes" */).then(c => wrapFunctional(c.default || c))
export const MapPointOfInterestPoiCommentCard = () => import('../../components/map/PointOfInterest/PoiCommentCard.vue' /* webpackChunkName: "components/map-point-of-interest-poi-comment-card" */).then(c => wrapFunctional(c.default || c))
export const MapPointOfInterestPoiComments = () => import('../../components/map/PointOfInterest/PoiComments.vue' /* webpackChunkName: "components/map-point-of-interest-poi-comments" */).then(c => wrapFunctional(c.default || c))
export const MapPointOfInterestPoiDetailsCampingInformation = () => import('../../components/map/PointOfInterest/PoiDetailsCampingInformation.vue' /* webpackChunkName: "components/map-point-of-interest-poi-details-camping-information" */).then(c => wrapFunctional(c.default || c))
export const MapPointOfInterestPoiFullDetails = () => import('../../components/map/PointOfInterest/PoiFullDetails.vue' /* webpackChunkName: "components/map-point-of-interest-poi-full-details" */).then(c => wrapFunctional(c.default || c))
export const MapPointOfInterestPoiNearbyPlaces = () => import('../../components/map/PointOfInterest/PoiNearbyPlaces.vue' /* webpackChunkName: "components/map-point-of-interest-poi-nearby-places" */).then(c => wrapFunctional(c.default || c))
export const MapPointOfInterestPoiOwner = () => import('../../components/map/PointOfInterest/PoiOwner.vue' /* webpackChunkName: "components/map-point-of-interest-poi-owner" */).then(c => wrapFunctional(c.default || c))
export const MapPointOfInterestPoiPhotos = () => import('../../components/map/PointOfInterest/PoiPhotos.vue' /* webpackChunkName: "components/map-point-of-interest-poi-photos" */).then(c => wrapFunctional(c.default || c))
export const MapPointOfInterestPoiShare = () => import('../../components/map/PointOfInterest/PoiShare.vue' /* webpackChunkName: "components/map-point-of-interest-poi-share" */).then(c => wrapFunctional(c.default || c))
export const MapPointOfInterestPoiSizeIndicator = () => import('../../components/map/PointOfInterest/PoiSizeIndicator.vue' /* webpackChunkName: "components/map-point-of-interest-poi-size-indicator" */).then(c => wrapFunctional(c.default || c))
export const MapPointOfInterestDetailsContent = () => import('../../components/map/PointOfInterest/PointOfInterestDetailsContent.vue' /* webpackChunkName: "components/map-point-of-interest-details-content" */).then(c => wrapFunctional(c.default || c))
export const MapPointOfInterestDetailsHeader = () => import('../../components/map/PointOfInterest/PointOfInterestDetailsHeader.vue' /* webpackChunkName: "components/map-point-of-interest-details-header" */).then(c => wrapFunctional(c.default || c))
export const MapPointOfInterestPreview = () => import('../../components/map/PointOfInterest/PointOfInterestPreview.vue' /* webpackChunkName: "components/map-point-of-interest-preview" */).then(c => wrapFunctional(c.default || c))
export const PointOfInterestStepperFormAttributeSelector = () => import('../../components/point_of_interest/stepper_form/AttributeSelector.vue' /* webpackChunkName: "components/point-of-interest-stepper-form-attribute-selector" */).then(c => wrapFunctional(c.default || c))
export const PointOfInterestStepperFormCampingInformation = () => import('../../components/point_of_interest/stepper_form/CampingInformation.vue' /* webpackChunkName: "components/point-of-interest-stepper-form-camping-information" */).then(c => wrapFunctional(c.default || c))
export const PointOfInterestStepperFormCommentForm = () => import('../../components/point_of_interest/stepper_form/CommentForm.vue' /* webpackChunkName: "components/point-of-interest-stepper-form-comment-form" */).then(c => wrapFunctional(c.default || c))
export const PointOfInterestStepperFormImagePreview = () => import('../../components/point_of_interest/stepper_form/ImagePreview.vue' /* webpackChunkName: "components/point-of-interest-stepper-form-image-preview" */).then(c => wrapFunctional(c.default || c))
export const PointOfInterestStepperFormStepFive = () => import('../../components/point_of_interest/stepper_form/StepFive.vue' /* webpackChunkName: "components/point-of-interest-stepper-form-step-five" */).then(c => wrapFunctional(c.default || c))
export const PointOfInterestStepperFormStepFour = () => import('../../components/point_of_interest/stepper_form/StepFour.vue' /* webpackChunkName: "components/point-of-interest-stepper-form-step-four" */).then(c => wrapFunctional(c.default || c))
export const PointOfInterestStepperFormStepOne = () => import('../../components/point_of_interest/stepper_form/StepOne.vue' /* webpackChunkName: "components/point-of-interest-stepper-form-step-one" */).then(c => wrapFunctional(c.default || c))
export const PointOfInterestStepperFormStepSix = () => import('../../components/point_of_interest/stepper_form/StepSix.vue' /* webpackChunkName: "components/point-of-interest-stepper-form-step-six" */).then(c => wrapFunctional(c.default || c))
export const PointOfInterestStepperFormStepThree = () => import('../../components/point_of_interest/stepper_form/StepThree.vue' /* webpackChunkName: "components/point-of-interest-stepper-form-step-three" */).then(c => wrapFunctional(c.default || c))
export const PointOfInterestStepperFormStepTwo = () => import('../../components/point_of_interest/stepper_form/StepTwo.vue' /* webpackChunkName: "components/point-of-interest-stepper-form-step-two" */).then(c => wrapFunctional(c.default || c))
export const ProsDashboard = () => import('../../components/pros/dashboard/Dashboard.vue' /* webpackChunkName: "components/pros-dashboard" */).then(c => wrapFunctional(c.default || c))
export const ProsDashboardEdit = () => import('../../components/pros/dashboard/Edit.vue' /* webpackChunkName: "components/pros-dashboard-edit" */).then(c => wrapFunctional(c.default || c))
export const ProsDashboardRatings = () => import('../../components/pros/dashboard/Ratings.vue' /* webpackChunkName: "components/pros-dashboard-ratings" */).then(c => wrapFunctional(c.default || c))
export const TripThemedTripHeader = () => import('../../components/trip/themed/ThemedTripHeader.vue' /* webpackChunkName: "components/trip-themed-trip-header" */).then(c => wrapFunctional(c.default || c))
export const TripThemedTripList = () => import('../../components/trip/themed/ThemedTripList.vue' /* webpackChunkName: "components/trip-themed-trip-list" */).then(c => wrapFunctional(c.default || c))
export const TripThemedTripSelectCategory = () => import('../../components/trip/themed/ThemedTripSelectCategory.vue' /* webpackChunkName: "components/trip-themed-trip-select-category" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}

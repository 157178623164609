import { render, staticRenderFns } from "./CarouselGuest.vue?vue&type=template&id=59c028ba&"
import script from "./CarouselGuest.vue?vue&type=script&lang=ts&"
export * from "./CarouselGuest.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseCarousel: require('/home/caramaps/site/pro/www2/releases/20241106101840/components/base/BaseCarousel.vue').default})
